const backofficeEN = {
  bo: {
    payments: {
      title: 'Payments',
      merchant_xref_id: 'Merchant ID',
      gateway: 'Payment Gateway',
      upgrade_pos_needed:
        'This feature is not supported on your current POS version. Please upgrade your POS to the latest version to gain access.',
      state: 'Status',
      state_reason_text: 'Status Reason',
      state_text: {
        data_collection: 'Data Collection',
        adjudication: 'Adjudication',
        accepted: 'Accepted',
        rejected: 'Rejected',
        blocked: 'Blocked',
        terminated: 'Terminated',
        payout_created: 'Payout Created',
        unknown: 'Unknown State',
      },
      state_reason_explanations: {
        fraud: 'The merchant was closed for fraud.',
        additional_documents_required:
          'The merchant needs to upload some additional documents to get verified.',
        high_risk_cb_rate: 'The account was closed due to high risk or high rate of chargebacks.',
        reported_user: 'The merchant was reported as fraudulent.',
        tos_violation: 'The merchant is operating against Terms of Service',
        country_not_supported:
          'The merchant is trying to KYC in a country other than US or Canada.',
        compliance: 'The risk team closed the account due to compliance reasons.',
        unsupported: 'Unsupported',
        closed_for_loss:
          "The merchant's account has maintained a negative balance for more than the allowed number of days.",
        account_closed:
          'Account was closed by payment processor. Reach out to processor for further information.',
        unknown: 'Unknown',
      },
      service: {
        wepay_cp: 'WePay Card Present',
        wepay_cnp: 'WePay Card Not Present',
        payrix_cp: 'Payrix Card Present',
        payrix_cnp: 'Payrix Card Not Present',
        worldpay_cp: 'Worldpay Card Present',
        worldpay_cnp: 'Worldpay Card Not Present',
      },
      settings: {
        title: 'Payment Settings',
        not_available_message: 'Payment settings not available for this venue.',
        not_available_description:
          'This page is only available for Payrix and Worldpay venues. For WePay venues, please use the CS Portal.',
        agent: {
          email: 'TouchBistro Email',
          helper_text: 'Enter your email to confirm you want to make this change',
        },
        express: {
          account_id: 'Account ID',
          account_token: 'Account Token',
          acceptor_id: 'Acceptor ID',
          default_terminal_id: 'Default Terminal ID',
        },
        update: {
          title: 'Update Credentials',
          submit: 'Update',
          unchanged: 'Credentials have not been altered',
          success: 'Successfully updated the merchant credentials',
        },
        confirm: {
          title: 'Hold Up',
          message:
            'You are making a change to critical merchant information. Please verify all values before saving.',
        },
        validation_error: {
          form_error: 'Please fill in all required fields correctly',
          required: 'This field is required',
          email_match: "Email doesn't match",
          merchant_xref_id: "This doesn't look like a valid Merchant ID",
          account_id: "This doesn't look like a valid Account ID",
          account_token: "This doesn't look like a valid Account Token",
          acceptor_id: "This doesn't look like a valid Acceptor ID",
        },
      },
      terminals: {
        title: 'Payment Terminals',
        new_title: 'Add Payment Terminal',
        edit_title: 'Edit Payment Terminal',
        add: 'Add Terminal',
        details: 'Terminal Details',
        name: 'Terminal Name',
        name_helper: 'Your terminal name will be displayed on your connected products.',
        validation: {
          field_max_length: '{{field}} is longer than {{max_length}}',
          unique_label: 'Enter a unique name that does not contain special characters',
          unique_xrefid: 'Enter a unique terminal ID that does not contain special characters.',
        },
        xrefid: 'Terminal ID',
        xrefid_helper: 'The unique ID assigned by your Payment Provider.',
        model: 'Terminal Model',
        model_helper: 'The model name of the terminal.',
        empty_helper: 'Set up a terminal to begin accepting payments from your guests.',
        back_button: 'Back to Payment Terminals',
        delete: {
          confirm: 'Are you sure you want to delete the following terminals:',
          confirm_caption: 'This action cannot be undone',
          button_cta: 'Delete',
          success: 'Terminal{{plural}} successfully deleted',
          failure: 'Terminal{{plural}} failed to delete. Please try again',
        },
        models: {
          lane3000: 'TouchBistro Payments Ingenico Lane/3000',
          link2500: 'TouchBistro Payments Ingenico Link/2500',
          dx8000: 'Moneris Go Plus (DX8000)',
          a35: 'Moneris Go Pin Pad (A35)',
        },
        missing_credentials_dialog: {
          title: 'Payment Credentials Required',
          message: 'Enter your payment credentials before proceeding with this step.',
          cta: 'Go to Payment Credentials',
        },
        missing_credentials_dialog_non_admin: {
          title: 'Payment Credentials Required',
          message_1: 'Payment credentials must be entered before terminals can accept payments.',
          message_2: 'You don’t have access to Payment Credentials. ',
          message_3: 'Please contact an admin for assistance.',
          cta: 'Okay',
        },
      },
      credentials: {
        title: 'Payment Credentials',
        header: 'In-Venue Payments',
        update: {
          success: 'Payment Credentials have been saved!',
          error: 'Failed to save Payment Credentials.',
        },
        confirmation: {
          title:
            'When changing payment credentials any incorrect information will impact in-venue payment operations.',
          message: 'Are you sure you want to make these changes?',
          confirm: 'Yes, Save Changes',
          cancel: 'No, Return to Payment Credentials',
        },
        moneris: {
          token: 'API Token',
          store_id: 'Store ID',
          validation: {
            merchant_xref_id: 'Must be a 13-digit numeric ID.',
            token: 'Must be a 20-character alphanumeric ID.',
            store_id: 'Must be a 10-character alphanumeric ID.',
          },
          helper_text: {
            merchant_xref_id: 'This 13-digit ID is found in your Welcome Email from Moneris.',
            token:
              'This 20-character token is found in the <0>Moneris Go Portal</0> under Settings > Developer Tools.',
            store_id: 'This 10-character ID is found in your Welcome Email from Moneris.',
          },
        },
        agent: {
          warning: 'Payment Credentials can only be edited by the venue.',
        },
        unsupported_gateway: 'Unsupported Payment Gateway',
        gateway_helper_text: 'This is who you are contracted to use for in-venue payments.',
      },
    },
  },
}

export default backofficeEN
