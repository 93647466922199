const venueDetailsES = {
  'acc.venue_info': 'Información del lugar',
  'acc.tax_settings': 'Configuración de impuestos',
  'payment_settings.payment_settings': '[ES] Payment Settings',
  'payment_settings.agreements': '[ES] Legal Terms and Conditions',
  'payment_settings.agreements.acknowledge': '[ES] I acknowledge',
  'payment_settings.agreements.offline_payments.payrix.body_1':
    "[ES] Enable this feature to store transactions in your payment device that can't be immediately processed in the event the payment gateway can't be reached (e.g., Internet is unavailable or the gateway is temporarily offline).",
  'payment_settings.agreements.offline_payments.payrix.body_2':
    '[ES] Your payment device has a default limit of storing $100 per transaction and a total limit of storing $1000 for all transactions. These limits can be adjusted. Once the limit has been reached, a popup will appear informing you that you have reached the maximum number of offline payments that can be stored on the card reader.',
  'payment_settings.agreements.offline_payments.payrix.body_3':
    '[ES] Stored card payments are not authorized (e.g., for credit availability, lost/stolen status) until connectivity returns and they are forwarded to the payment gateway. Stored payments may still be declined.',
  'payment_settings.agreements.offline_payments.payrix.body_4':
    '[ES] Disable Offline Payments if you do not want to accept this risk.',
  'payment_settings.save_success': '[ES] Changes successfully saved!',
  'payment_settings.save_failure': '[ES] Unable to save. Please try again.',
  'payment_settings.additional_message': '[ES] Payment Terminal Settings',
  'payment_settings.additional_message.cta.moneris': '[ES] Visit the Moneris Go Portal',
  'payment_settings.additional_message.banner.default':
    '[ES] Need to update payment terminal settings?',
  'payment_settings.additional_message.banner.moneris.body_1':
    '[ES] Receipt printing, tip values, and other terminal settings can be configured in the Moneris Go Portal.',
  'payment_settings.auto_close_table': '[ES] Auto-Close Table After Payment',
  'payment_settings.auto_close_table.description':
    '[ES] This setting only applies for terminals that have been configured to accept pay-at-table payments.',
  'payment_settings.tip': '[ES] Tip Prompt',
  'payment_settings.tip.description': '[ES] Prompts for a tip on the terminal or receipt.',
  'payment_settings.tip.mode': '[ES] Location of Tip Prompt',
  'payment_settings.tip.mode.options.terminal': '[ES] Tip on Terminal',
  'payment_settings.tip.mode.options.receipt': '[ES] Tip on Receipt',
  'payment_settings.tip.calculation': '[ES] Tip Calculation',
  'payment_settings.tip.calculation.description':
    '[ES] Select whether the tip is calculated post-tax, pre-tax, pre-discount.',
  'payment_settings.tip.calculation.options.post_tax': '[ES] Post-Tax',
  'payment_settings.tip.calculation.options.pre_tax': '[ES] Pre-Tax',
  'payment_settings.tip.calculation.options.pre_discount': '[ES] Pre-Discount',
  'payment_settings.tip.recommendation': '[ES] Suggested Tip Amounts (%)',
  'payment_settings.tip.recommendation.description':
    '[ES] Specify the suggested tip amounts that will display on the terminal or receipt.',
  'payment_settings.offline_payments.note':
    '[ES] ** Offline Payments is only available for Tip on Terminal.',
  'payment_settings.offline_payments.agent_note':
    '[ES] Offline Payments is only editable by merchants. Please ask the merchant to make any required changes.',
  'payment_settings.offline_payments': '[ES] Offline Payments',
  'payment_settings.offline_payments.description':
    '[ES] Ability to continue processing payments when offline by storing transaction records on the terminal.',
  'payment_settings.offline_payments.max_per_transaction_limit': '[ES] Per Transaction Limit',
  'payment_settings.offline_payments.max_per_transaction_limit.description':
    '[ES] Max transaction size when offline.',
  'payment_settings.offline_payments.max_total_limit': '[ES] Total Limit',
  'payment_settings.offline_payments.max_total_limit.description':
    '[ES] Sum of all offline transaction amounts.',
  'payment_settings.offline_payments.per_terminal': '[ES] per terminal',
  'payment_settings.pre_authorized_payments': '[ES] Pre-Authorized Payments',
  'payment_settings.pre_authorized_payments.description':
    '[ES] Specify the initial amount to be held on the guest’s card.',
  'payment_settings.pre_authorized_payments.order_types': '[ES] Order Types',
  'payment_settings.pre_authorized_payments.order_types.description':
    '[ES] Select which order types you would like to allow pre-authorizations for.',
  'payment_settings.pre_authorized_payments.order_types.options.bar_tabs': '[ES] Bar Tabs',
  'payment_settings.pre_authorized_payments.order_types.options.tables': '[ES] Tables',
  'payment_settings.pre_authorized_payments.amount': '[ES] Authorized Amount',
  'payment_settings.pre_authorized_payments.amount.description':
    '[ES] Specify the initial amount to be held on the guest’s card.',
  'payment_settings.pre_authorized_payments.is_credit_card_mandatory':
    '[ES] Make Credit Card Mandatory',
  'payment_settings.pre_authorized_payments.is_credit_card_mandatory.description':
    '[ES] A credit card must be pre-authorized in order to proceed with the order.',
  'payment_settings.batch_close': '[ES] Batch Close',
  'payment_settings.batch_close.description':
    '[ES] Select when your batch of transactions will automatically close each day for settlement.',
  'payment_settings.batch_close.time': '[ES] Time',
  'payment_settings.batch_close.guidance':
    '[ES] Tip: Select a batch time that is outside of your service hours for easier report reconciliation.',
  'payment_settings.errors.invalid_time': '[ES] Please enter a valid time (e.g. 2:15pm)',
  'payment_settings.errors.min_max': '[ES] Value must be between {{min}} and {{max}}',
  'pos.receipt_settings': 'Configuración de recibos',
  'pos.receipt_frequency': 'Frecuencia de recepción',
  'pos.receipt_frequency_info':
    'Para cada tipo de pago, por favor elija la opción si las copias del recibo del comercio y del cliente se imprimirán siempre, nunca se imprimirán o se imprimirán cuando se solicite (al hacer clic en el botón “Imprimir recibo”).',
  'pos.receipt_frequency_info_ios':
    'Por favor elija la opción si las copias del recibo del comercio y del cliente se imprimirán siempre, nunca se imprimirán o se imprimirán cuando se solicite (al hacer clic en el botón “Imprimir recibo”).',
  'pos.print_frequency_never': 'Nunca se imprimirán',
  'pos.print_frequency_always': 'Imprimirán siempre',
  'pos.print_frequency_prompt': 'Imprimirán cuando se solicite',
  'pos.payment_type': 'Tipo de pago',
  'pos.merchant_copy': 'Copia del comerciante',
  'pos.customer_copy': 'Copia del cliente',
  'pos.save_changes': 'Guardar cambios',
  'pos.discard_changes': 'Descartar cambios',
  'pos.save_success': 'La configuración del recibo se ha actualizado.',
  'pos.order_settings_save_success': '[ES] Receipt settings has been updated',
  'pos.receipt_layout': 'Diseño de recibo',
  'pos.receipt_layout_info':
    'Por favor seleccione los campos opcionales que aparecerán en los recibos impresos del comercio y del cliente',
  'pos.venue_address': 'Dirección del lugar',
  'pos.email_address': 'Dirección de correo electrónico',
  'pos.phone_number': 'Número de teléfono',
  'pos.order_type': 'Tipo de orden',
  'pos.server': 'Servidor',
  'pos.tax': 'Números de identificación fiscal',
  'pos.footer_notes': 'Notas al pie',
  'pos.cash': 'Dinero',
  'pos.non_integrated': 'No integrado',
  'pos.order_ticket_number': 'Número de ticket de pedido',
  'pos.integrated': 'Integrado',
  'pos.inactive_timeout_5': '[ES] 5 Minutes',
  'pos.inactive_timeout_10': '[ES] 10 Minutes',
  'pos.inactive_timeout_15': '[ES] 15 Minutes',
  'pos.inactive_timeout_20': '[ES] 20 Minutes',
  'pos.inactive_timeout_30': '[ES] 30 Minutes',
  'pos.inactive_timeout_45': '[ES] 45 Minutes',
  'pos.inactive_timeout_60': '[ES] 1 Hour',
  'pos.order-management': '[ES] Order Management',
  'pos.order_management_info':
    '[ES] Configure additional options around the movement of orders, from start to finish, on the POS (Point of Sale)',
  'pos.toggle_order_state': '[ES] Enable "Ready" Order State',
  'pos.toggle_order_state_info':
    '[ES] This setting is useful if your venue operates a pickup counter or you would like to track when orders are collected',
  'pos.preparation_time_info_error': '[ES] Preparation time must be between 1 and 120',
  'pos.preparation_time': '[ES] Preparation time (in minutes)',
  'pos.preparation_time_info': '[ES] value must be between 1 and 120',
  'pos.inactivity_window': '[ES] Inactivity Window',
  'pos.moving_orders': '[ES] Moving orders through the “In Progress” state',
  'pos.moving_orders_info':
    '[ES] Controls how orders move out of the “in progress” state manually (by the user) or automatically (by configured prep time)',
  'pos.inactivity_window_info':
    '[ES] The amount of time that the POS (Point of Sale) will remain unlocked before returning to the PIN screen, after a period of inactivity',
  'pos.configure_register': '[ES] Configure Register',
  'pos.configure_register_description':
    '[ES] Customize the Register screen to fit your venue’s workflow',
  'pos.cash_bar_toggle_label': '[ES] Include Quick Cash buttons on the Register Screen',
  'pos.cash_bar_toggle_description':
    '[ES] These buttons enable staff to take cash orders in 1-tap, and are helpful for venues that make many cash transactions.',
  'pos.moving_orders_manually': 'Manually',
  'pos.moving_orders_automatically': '[ES] Automatically (based on PreparationTime)',
  'pos.moving_orders_note':
    '[ES] Note: Unpaid orders cannot move to “Closed” unless they are “Cancelled”. If the “Ready” tab has been disabled, orders will remain in the “In Progress” tab beyond the preparation time limit, until paid in full or cancelled manually by the user.',
  'pos.order_settings_saved_failure': '[ES] There was an error saving order settings',
  'branding.branding': '[ES] Branding',
  'branding.icon_header': '[ES] Logo',
  'branding.icon_subtitle': '[ES] Upload your logo to provide a consistent diner experience.',
  'branding.banner_header': '[ES] Banner Image',
  'branding.banner_subtitle':
    '[ES] Upload a banner image to showcase your menu or dining experience.',
  'branding.color_theme_header': '[ES] Color Theme',
  'branding.color_theme_subtitle': `[ES] Select or input your brand's primary and secondary colors.`,
  'branding.color_theme_live_preview': '[ES] Live Preview',
  'branding.color_primary_color_label': '[ES] Primary Color',
  'branding.color_secondary_color_label': '[ES] Secondary Color',
  'branding.color_primary_button': '[ES] Primary Button',
  'branding.color_link_button': '[ES] Link and icon',
  'branding.color_secondary_button': '[ES] Secondary Button',
  'branding.color_tertiary_button': '[ES] Tertiary Button',
  'branding.color_button_fails_wcag_contrast':
    '[ES] This color may be difficult for your diners to see.',
  'branding.color_white_error_primary': '[ES] Primary color cannot be white (#FFFFFF).',
  'branding.color_white_error_secondary': '[ES] Secondary color cannot be white (#FFFFFF).',
  'branding.same_color_error_primary':
    '[ES] Primary color cannot use the same #HEX as the secondary color.',
  'branding.same_color_error_secondary':
    '[ES] Secondary color cannot use the same #HEX as the primary color.',
  'branding.settings_saved_success': '[ES] Branding settings saved successfully',
  'branding.settings_saved_failure': '[ES] There was an error saving branding settings',
  'settings.settings': 'Configuración',
  'hours_scheduling.hours_scheduling': 'Horas y programación',
  'hours_scheduling.configure_day_parts': 'Configuración partes del día',
  'hours_scheduling.configure_day_parts_description':
    'Cree y organice sus partes del día para filtrar rápidamente entre horarios comerciales definidos y verlos en sus informes.',
  'hours_scheduling.no_day_parts_found': 'Actualmente no tienes ninguna parte del día.',
  'hours_scheduling.hours_of_operation': '[ES] Hours of Operation',
  'hours_scheduling.hours_of_operation_desc':
    '[ES] Indicate when you are open for in-venue service.',
  'hours_scheduling.add_hours': '[ES] Add Hours',
  'hours_scheduling.start_time': '[ES] Start Time',
  'hours_scheduling.end_time': '[ES] End Time',
  'hours_scheduling.hours_of_operation.days_error_message':
    '[ES] Please select the day(s) this hours of operation is available.',
  'hours_scheduling.hours_of_operation.exceptions': '[ES] Exceptions',
  'hours_scheduling.hours_of_operation.exceptions.desc':
    '[ES] Days and times you are open for a special occasion, event, holiday, or other reason. Exceptions will override your normal Hours of Operation for the specified day(s).',
  'hours_scheduling.hours_of_operation.exceptions.add': '[ES] Add Exception',
  'hours_scheduling.hours_of_operation.closures': '[ES] Closures',
  'hours_scheduling.hours_of_operation.closures.desc':
    '[ES] Days and times you are closed for holidays, private events, renovations, or other reasons. Closures will override your regular Hours of Operation and any Exceptions you have created for the specified day(s).',
  'hours_scheduling.hours_of_operation.closures.add': '[ES] Add Closure',
  'hours_scheduling.hours_of_operation.date': '[ES] Date',
  'hours_scheduling.hours_of_operation.start_and_end_time': '[ES] Start & End Time',
  'hours_scheduling.hours_of_operation.exceptions.empty_message':
    '[ES] You currently have no Exceptions to be displayed.',
  'hours_scheduling.hours_of_operation.closures.empty_message':
    '[ES] You currently have no Closures to be displayed.',
  'hours_scheduling.hours_of_operation.exceptions.delete':
    '[ES] The exception was deleted successfully',
  'hours_scheduling.hours_of_operation.closures.delete':
    '[ES] The closure was deleted successfully',
  'hours_scheduling.hours_of_operation.exceptions.delete_title':
    '[ES] Are you sure you want to delete the exception on {{date}}?',
  'hours_scheduling.hours_of_operation.exceptions.delete_confirm_btn': '[ES] Delete Exception',
  'hours_scheduling.hours_of_operation.closures.delete_title':
    '[ES] Are you sure you want to delete the closure on {{date}}?',
  'hours_scheduling.hours_of_operation.closures.delete_confirm_btn': '[ES] Delete Closure',
  'hours_scheduling.hours_of_operation.exceptions_closures_subtitle':
    '[ES] This action is irreversible.',
  'hours_scheduling.add_exception': '[ES] Add Exception',
  'hours_scheduling.edit_exception': '[ES] Edit Exception',
  'hours_scheduling.add_closure': '[ES] Add Closure',
  'hours_scheduling.edit_closure': '[ES] Edit Closure',
  'hours_scheduling.when': '[ES] When',
  'hours_scheduling.all_day': '[ES] All Day',
  'hours_scheduling.exception_added_success': '[ES] Exception was added successfully',
  'hours_scheduling.exception_updated_success': '[ES] Exception was updated successfully',
  'hours_scheduling.closure_added_success': '[ES] Closure was added successfully',
  'hours_scheduling.closure_updated_success': '[ES] Closure was updated successfully',
  'hours_scheduling.hours_of_operation.conflict_service_time':
    '[ES] Heads up! The highlighted Hours of Operation below do not fit within your Service Time window of {{startTime}} to {{endTime}}.',
  'hours_scheduling.service_time.conflict_message':
    '[ES] Heads up! You have Hours of Operation that do not fit within your Service Time window. Your reports may be inaccurate.',
  'venue_details.venue_details': '[ES] Venue Details',
  'venue_details.basic_details': '[ES] Basic Details',
  'venue_details.contact': '[ES] Contact Details',
  'venue_details.online_presence': '[ES] Online Presence',
  'venue_details.venue_name': '[ES] Venue Name',
  'venue_details.venue_id': '[ES] Venue ID',
  'venue_details.business_number': '[ES] Business Number',
  'venue_details.currency': '[ES] Currency',
  'venue_details.currency_placeholder': '[ES] Select a currency',
  'venue_details.business_description': '[ES] Business Description',
  'venue_details.business_description_placeholder':
    "[ES] Enter a description of what you're known for",
  'venue_details.specialty': '[ES] Specialty',
  'venue_details.specialty_placeholder': '[ES] Enter your Specialty',
  'venue_details.parking_info': '[ES] Parking Info',
  'venue_details.dress_code': '[ES] Dress Code',
  'venue_details.categories_and_cuisine_types': '[ES] Categories and Cuisine Types',
  'venue_details.optional_sublabel': '[ES] Optional',
  'venue_details.specialty_length_restrict':
    '[ES] Oops! Specialty has to be 30 characters or less.',
  'venue_details.items_selected': '[ES] {{count}} Selected',
  'venue_details.select_option': '[ES] Select Option',
  'venue_details.select-options': '[ES] Select Options',
  'venue_details.save_success': '[ES] Venue details saved successfully!',
  'venue_details.save_failed': '[ES] An error occurred while saving venue details',
  'venue_details.warning.title': '[ES] Are you sure you want to change this information?',
  'venue_details.warning.body':
    "[ES] <2>Changing the <1>venue name, business number, or country</1>, could impact the venue's TouchBistro Payments account and existing integrations (i.e. DoorDash Drive), if applicable. Please ensure these changes are also applied to the venue's TouchBistro Payments account and communicated to any applicable 3rd parties.</2>",
  'venue_details.address_line_1': '[ES] Address',
  'venue_details.address_line_2': '[ES] Apartment, Suite, etc.',
  'venue_details.city': '[ES] City',
  'venue_details.state_province': '[ES] State/Province',
  'venue_details.state_province.length_error': '[ES] Must be less than 26 characters',
  'venue_details.state_province.pattern_error':
    '[ES] Must look like ON, NY, or other 2 letter state/province code',
  'venue_details.zip_postal_code': '[ES] Zip/Postal Code',
  'venue_details.country': '[ES] Country',
  'venue_details.country.error':
    "[ES] Unfortunately, you cannot change the address's country. Please reach out to support if this is needed.",
  'venue_details.email': '[ES] Email',
  'venue_details.email.pattern_error':
    '[ES] Email must be a valid email address. E.g.: example@example.com',
  'venue_details.phone': '[ES] Phone',
  'venue_details.your_website': '[ES] Your Website',
  'venue_details.menu_links': '[ES] Menu Links',
  'venue_details.social_media': '[ES] Social Media',
  'venue_details.enter_a_valid_url': '[ES] Please enter a valid url.',
  'venue_details.enter_a_url': '[ES] Please enter a url.',
  'venue_details.enter_a_name': '[ES] Please enter a name.',
  'venue_details.menu_link_name_restrict':
    '[ES] Oops! Menu Link Name has to be 30 characters or less.',
  'venue_details.social_media_name_restrict':
    '[ES] Oops! Custom Social Media Label has to be 30 characters or less.',
  'venue_details.enter_name': '[ES] Enter Name',
  'venue_details.select_type': '[ES] Select Type',
  'venue_details.select_type_required': '[ES] Please select a type',
  'venue_details.custom_social_media': '[ES] Custom Social Media Type Here',
  'venue_details.add_menu_link': '[ES] Add Menu Link',
  'venue_details.add_social_media': '[ES] Add Social Media',
  'venue_details.parkingInfo.none': '[ES] No Parking',
  'venue_details.parkingInfo.street': '[ES] Street Parking',
  'venue_details.parkingInfo.lot': '[ES] Lot Parking',
  'venue_details.parkingInfo.pay': '[ES] Pay Parking',
  'venue_details.parkingInfo.valet': '[ES] Valet Parking',
  'venue_details.dressCode.casual': '[ES] Casual',
  'venue_details.dressCode.business_casual': '[ES] Business Casual',
  'venue_details.dressCode.jacket_preferred': '[ES] Jacket Preferred',
  'venue_details.dressCode.jacket_required': '[ES] Jacket Required',
  'venue_details.dressCode.jacket_and_tie_required': '[ES] Jacket & Tie Required',
  'venue_details.dressCode.formal_attire': '[ES] Formal Attire',
  'venue_details.cuisineTypes.african': '[ES] African',
  'venue_details.cuisineTypes.algerian': '[ES] Algerian',
  'venue_details.cuisineTypes.american': '[ES] American',
  'venue_details.cuisineTypes.argentina': '[ES] Argentina',
  'venue_details.cuisineTypes.asian': '[ES] Asian',
  'venue_details.cuisineTypes.barbecue': '[ES] Barbecue',
  'venue_details.cuisineTypes.belgian': '[ES] Belgian',
  'venue_details.cuisineTypes.bistro': '[ES] Bistro',
  'venue_details.cuisineTypes.brasserie': '[ES] Brasserie',
  'venue_details.cuisineTypes.brazilian': '[ES] Brazilian',
  'venue_details.cuisineTypes.breakfast': '[ES] Breakfast',
  'venue_details.cuisineTypes.bring_your_own_wine': '[ES] Bring your own wine',
  'venue_details.cuisineTypes.brunch': '[ES] Brunch',
  'venue_details.cuisineTypes.cajun_creole': '[ES] Cajun Creole',
  'venue_details.cuisineTypes.canadian': '[ES] Canadian',
  'venue_details.cuisineTypes.caribbean': '[ES] Caribbean',
  'venue_details.cuisineTypes.casual_fine_dining': '[ES] Casual fine dining',
  'venue_details.cuisineTypes.charcuterie': '[ES] Charcuterie',
  'venue_details.cuisineTypes.chinese': '[ES] Chinese',
  'venue_details.cuisineTypes.cocktail': '[ES] Cocktail',
  'venue_details.cuisineTypes.coffee': '[ES] Coffee',
  'venue_details.cuisineTypes.communal_seating': '[ES] Communal seating',
  'venue_details.cuisineTypes.contemporary_cuisine': '[ES] Contemporary cuisine',
  'venue_details.cuisineTypes.continental': '[ES] Continental',
  'venue_details.cuisineTypes.craft_beer': '[ES] Craft beer',
  'venue_details.cuisineTypes.craft_cider': '[ES] Craft cider',
  'venue_details.cuisineTypes.creperie': '[ES] Creperie',
  'venue_details.cuisineTypes.cuban': '[ES] Cuban',
  'venue_details.cuisineTypes.deli': '[ES] Deli',
  'venue_details.cuisineTypes.desserts_and_sweets': '[ES] Desserts and sweets',
  'venue_details.cuisineTypes.eastern_european': '[ES] Eastern European',
  'venue_details.cuisineTypes.ethiopian': '[ES] Ethiopian',
  'venue_details.cuisineTypes.family_friendly': '[ES] Family friendly',
  'venue_details.cuisineTypes.filipino': '[ES] Filipino',
  'venue_details.cuisineTypes.fine_dining': '[ES] Fine dining',
  'venue_details.cuisineTypes.fish': '[ES] Fish',
  'venue_details.cuisineTypes.fondue': '[ES] Fondue',
  'venue_details.cuisineTypes.french': '[ES] French',
  'venue_details.cuisineTypes.french_bistro': '[ES] French bistro',
  'venue_details.cuisineTypes.generic': '[ES] Generic',
  'venue_details.cuisineTypes.german': '[ES] German',
  'venue_details.cuisineTypes.gluten_free_cooking': '[ES] Gluten free cooking',
  'venue_details.cuisineTypes.greek': '[ES] Greek',
  'venue_details.cuisineTypes.grill': '[ES] Grill',
  'venue_details.cuisineTypes.halal': '[ES] Halal',
  'venue_details.cuisineTypes.hamburgers': '[ES] Hamburgers',
  'venue_details.cuisineTypes.hungarian': '[ES] Hungarian',
  'venue_details.cuisineTypes.indian': '[ES] Indian',
  'venue_details.cuisineTypes.indonesian': '[ES] Indonesian',
  'venue_details.cuisineTypes.international': '[ES] International',
  'venue_details.cuisineTypes.irish': '[ES] Irish',
  'venue_details.cuisineTypes.italian': '[ES] Italian',
  'venue_details.cuisineTypes.italian_bistro': '[ES] Italian bistro',
  'venue_details.cuisineTypes.jamaican': '[ES] Jamaican',
  'venue_details.cuisineTypes.japanese': '[ES] Japanese',
  'venue_details.cuisineTypes.jewish': '[ES] Jewish',
  'venue_details.cuisineTypes.korean': '[ES] Korean',
  'venue_details.cuisineTypes.kosher': '[ES] Kosher',
  'venue_details.cuisineTypes.large_groups': '[ES] Large groups',
  'venue_details.cuisineTypes.latin': '[ES] Latin',
  'venue_details.cuisineTypes.lebanese': '[ES] Lebanese',
  'venue_details.cuisineTypes.lunch': '[ES] Lunch',
  'venue_details.cuisineTypes.market': '[ES] Market',
  'venue_details.cuisineTypes.mediterranean': '[ES] Mediterranean',
  'venue_details.cuisineTypes.mexican': '[ES] Mexican',
  'venue_details.cuisineTypes.microbrewery': '[ES] Microbrewery',
  'venue_details.cuisineTypes.middle_eastern': '[ES] Middle Eastern',
  'venue_details.cuisineTypes.moroccan': '[ES] Moroccan',
  'venue_details.cuisineTypes.nepalese': '[ES] Nepalese',
  'venue_details.cuisineTypes.north_american': '[ES] North American',
  'venue_details.cuisineTypes.outdoor': '[ES] Outdoor',
  'venue_details.cuisineTypes.oyster_bar': '[ES] Oyster bar',
  'venue_details.cuisineTypes.peruvian': '[ES] Peruvian',
  'venue_details.cuisineTypes.pizzeria': '[ES] Pizzeria',
  'venue_details.cuisineTypes.polish': '[ES] Polish',
  'venue_details.cuisineTypes.portuguese': '[ES] Portuguese',
  'venue_details.cuisineTypes.pretzels': '[ES] Pretzels',
  'venue_details.cuisineTypes.pub': '[ES] Pub',
  'venue_details.cuisineTypes.quebec': '[ES] Quebec',
  'venue_details.cuisineTypes.regional': '[ES] Regional',
  'venue_details.cuisineTypes.rotisserie': '[ES] Rotisserie',
  'venue_details.cuisineTypes.russian': '[ES] Russian',
  'venue_details.cuisineTypes.sandwiches': '[ES] Sandwiches',
  'venue_details.cuisineTypes.sausages': '[ES] Sausages',
  'venue_details.cuisineTypes.seafood': '[ES] Seafood',
  'venue_details.cuisineTypes.smoked_meat': '[ES] Smoked meat',
  'venue_details.cuisineTypes.spanish': '[ES] Spanish',
  'venue_details.cuisineTypes.steak_house': '[ES] Steak house',
  'venue_details.cuisineTypes.sugar_shack': '[ES] Sugar shack',
  'venue_details.cuisineTypes.sushi': '[ES] Sushi',
  'venue_details.cuisineTypes.switzerland': '[ES] Switzerland',
  'venue_details.cuisineTypes.tapas': '[ES] Tapas',
  'venue_details.cuisineTypes.tartares': '[ES] Tartares',
  'venue_details.cuisineTypes.terroir': '[ES] Terroir',
  'venue_details.cuisineTypes.thai': '[ES] Thai',
  'venue_details.cuisineTypes.tunisian': '[ES] Tunisian',
  'venue_details.cuisineTypes.turkish': '[ES] Turkish',
  'venue_details.cuisineTypes.vegan': '[ES] Vegan',
  'venue_details.cuisineTypes.vegetarian': '[ES] Vegetarian',
  'venue_details.cuisineTypes.vietnamese': '[ES] Vietnamese',
  'venue_details.cuisineTypes.west_coast': '[ES] West Coast',
  'venue_details.cuisineTypes.wine_bar': '[ES] Wine bar',
  service_charges: '[ES] Service Charges',
  'service_charges.create': '[ES] Create Service Charge',
  'service_charges.delivery': '[ES] Delivery',
  'service_charges.delivery.definition':
    '[ES] Additional settings to trigger when the service chrge is automatically applied to a delivery order',
  'service_charges.delivery.waive_charge_conditions':
    '[ES] Waive this fee if it’s more than or equal to this amount ($)',
  'service_charges.delivery.assign_to_boundary': '[ES] Assign to an existing Delivery Boundary',
  'service_charges.delivery.boundaries': '[ES] Assign to an existing Delivery Boundary',
  'service_charges.delivery.boundary_rule':
    '[ES] must be configured before a service charge can be assigned to it.',
  'service_charges.details': '[ES] Service Charge Details',
  'service_charges.details.definition':
    '[ES] Service charges are additional charges added to a customer’s order to help cover the cost of the services provided by your business. Service charges should not be based on payment methods like credit cards fees.',
  'service_charges.details.charge_amount': '[ES] Fee Amount',
  'service_charges.details.charge_type': '[ES] What is the fee type?',
  'service_charges.details.charge_type.PERCENTAGE': '[ES] Fixed Percentage',
  'service_charges.details.charge_type.DOLLAR': '[ES] Fixed Amount',
  'service_charges.details.charge_type.OPEN': '[ES] Open Amount',
  'service_charges.details.charge_when_to_apply':
    '[ES] When would you like to apply the service fee?',
  'service_charges.details.charge_when_to_apply.post_discount': '[ES] Post-Discount',
  'service_charges.details.charge_when_to_apply.pre_discount': '[ES] Pre-Discount',
  'service_charges.details.charge_who_should_receive': '[ES] Who should receive the service fee?',
  'service_charges.details.charge_who_should_receive.VENUE': '[ES] The Venue',
  'service_charges.details.charge_who_should_receive.VENUE.description':
    '[ES] The service charge given to the venue will be reported as sales',
  'service_charges.details.charge_who_should_receive.EMPLOYEE': '[ES] The Employee',
  'service_charges.details.charge_who_should_receive.EMPLOYEE.description':
    '[ES] The service charge given to the employee will be reported as a gratuity',
  'service_charges.details.label': '[ES] Service Charge Name',
  'service_charges.details.label_required': '[ES] Service Charge Name is required',
  'service_charges.details.label_duplicate_error':
    '[ES] Another service charge with the same name already exists.',
  'service_charges.details.amount_required': '[ES] Service Charge Amount is required',
  'service_charges.form_percentage_amount_validation':
    '[ES] The service charge percentage must be between 0 - 100.',
  'service_charges.form_dollar_amount_validation': '[ES] The service charge has limit of $1000',
  'service_charges.details.label_validation_length':
    '[ES] Service charge name must be within 1 - 60 characters',
  'service_charges.order_types': '[ES] Order Types',
  'service_charges.order_types.instruction':
    '[ES] Choose which order types this service charge will automatically apply to.',
  'service_charges.order_types.err_required':
    '[ES] Must select as least one order type to create a Service Charge',
  'service_charges.order_types.manual': '[ES] Manual',
  'service_charges.order_types.manual.description':
    '[ES] Manually add the service charge to an order (Cannot be applied with other order types)',
  'service_charges.order_types.527931bf-5e0e-40e5-b731-ebe40fc288e2': '[ES] Dine In',
  'service_charges.order_types.527931bf-5e0e-40e5-b731-ebe40fc288e2.description':
    '[ES] Automatically apply the service charge to every dine in order',
  'service_charges.order_types.527931bf-5e0e-40e5-b731-ebe40fc288e2.settings':
    '[ES] Additional settings to trigger when the service charge is applied to a dine-in order',
  'service_charges.order_types.527931bf-5e0e-40e5-b731-ebe40fc288e2.min_party_size':
    '[ES] Minimum party size',
  'service_charges.order_types.527931bf-5e0e-40e5-b731-ebe40fc288e2.min_party_size.required':
    '[ES] Minimum party size is required',
  'service_charges.order_types.db826f17-616e-4122-bcb3-17f2bdf9426e': '[ES] Bar Tab',
  'service_charges.order_types.db826f17-616e-4122-bcb3-17f2bdf9426e.description':
    '[ES] Automatically apply the service charge to every bar tab',
  'service_charges.order_types.69d81a01-1d1f-40bf-8b9e-495b9c7766ab': '[ES] Takeout',
  'service_charges.order_types.69d81a01-1d1f-40bf-8b9e-495b9c7766ab.description':
    '[ES] Automatically apply the service charge to every takeout order (TB Online Ordering & in-house. Excluding 3rd party apps)',
  'service_charges.order_types.d7804873-7c58-4389-b095-9d02de96ceca': '[ES] Delivery',
  'service_charges.order_types.d7804873-7c58-4389-b095-9d02de96ceca.description':
    '[ES] Automatically apply the service charge to every delivery order (TB Online ordering & in-house)',
  'service_charges.taxes': 'Impuestos',
  'service_charges.taxes.apply': '[ES] Apply Tax?',
  'service_charges.taxes.definition':
    '[ES] Choose whether or not taxes should be applied to this service charge',
  'service_charges.taxes.apply.yes': 'Si',
  'service_charges.taxes.apply.no': 'No',
  service_charge_name: '[ES] Service Charge',
  service_charge_type: '[ES] Type',
  service_charge_amount: '[ES] Amount',
  service_charge_fulfillment: '[ES] Fulfillment',
  service_charge_taxes: '[ES] Taxes',
  service_charge_status: '[ES] Status',
  'service_charge.empty.message': "[ES] You currently don't have any Service Charges",
  'service_charge.delete_modal.title': "[ES] Are you sure you want to delete \n'{{label}}'?",
  'service_charge.delete.modal.message':
    '[ES] Deleting this service charge will not affect your past reports',
  'service_charge.delete.button.message': '[ES] Delete Service Charge',
  'service_charges.taxes.select':
    '[ES] Select the taxes that will be applied to the service charge',
  'service_charges.oo.configure_link':
    '<2>[ES] View <1>Service Charges</1> to configure your delivery charges</2>',
  'service_charges.edit': '[ES] Edit Service Charge',
  'service_charges.updated': '[ES] {{label}} has been updated',
  'delete_service_charge.success': '[ES] {{label}} has been deleted from Service Charges',
  delete_service_charge: '[ES] Delete Service Charge',
  'service_charges.no_tax_configured':
    '[ES] There are no taxes set up with this restaurant. Please set up the taxes from the Venue Info section.',
}

export default venueDetailsES
